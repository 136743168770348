@import '../abstracts/constants.scss';

.Toastify__toast {
  min-height: 80px;
  right: 0px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 64px;
  padding-right: 20px;
  box-shadow: $shadow-chat;
  background-color: $color-white;
  font-family: 'Open Sans';
  font-size: 1.2rem;
  font-weight: $open-sans-semibold;
  line-height: 1.33;
  color: $color-black;
  border-radius: 5px;

  &:before {
    content: '';
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    filter: invert(99%) sepia(61%) saturate(699%) hue-rotate(178deg) brightness(113%) contrast(100%);
    background-size: 36px auto !important;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 53px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
}

.Toastify__toast--default {
  border-left: 5px solid $color-white;
}

.Toastify__toast--info {
  border-left: 5px solid $color-info;

  &:before {
    background: url(/assets/icons/ic-info.svg) center/contain no-repeat;
  }

  &:after {
    background-color: $color-info;
  }
}

.Toastify__toast--success {
  border-left: 5px solid $color-ok;

  &:before {
    background: url(/assets/icons/ic-ok.svg) center/contain no-repeat;
  }

  &:after {
    background-color: $color-ok;
  }
}

.Toastify__toast--warning {
  border-left: 5px solid $color-warning;

  &:before {
    background: url(/assets/icons/ic-warning.svg) center/contain no-repeat;
  }

  &:after {
    background-color: $color-warning;
  }
}

.Toastify__toast--error {
  border-left: 5px solid $color-error;

  &:before {
    background: url(/images/warning.svg) center/contain no-repeat;
  }

  &:after {
    background-color: $color-error;
  }
}
