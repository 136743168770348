// Weights
$open-sans-regular: 400;
$open-sans-semibold: 600;
$open-sans-bold: 700;
$roboto-regular: 400;
$roboto-semibold: 500;
$roboto-bold: 700;

// Colors
$color-black: #333333;
$color-blue: #185573;
$color-blue-light: #f6f9fb;
$color-blue-light2: #e5eff5;
$color-blue-light3: #f0f5f8;
$color-blue-dark: #134863;
$color-gray: #666;
$color-gray-medium: #999;
$color-gray-light: #ccc;
$color-gray-light2: #ebebeb;
$color-error: #e02020;
$color-info: $color-blue;
$color-ok: #579e0d;
$color-yellow: #ffd000;
$color-warning: #e28f01;
$color-white: #ffffff;
$color-white2: #fafafa;

$color-panel: #a4bcc8;

$input-disabled: #f2f2f2;
$button-disabled: #f2f2f2;
$button-disabled-font: $color-gray-medium;
$button-primary-font: $color-white;
$button-primary: $color-blue;
$button-primary-hover: #46778f;
$button-primary-pressed: #11415a;
$button-secondary-font: #262626;
$button-secondary: #e6e6e6;
$button-secondary-pressed: $color-gray-light;
$button-white: $color-white;
$button-white-border: $color-gray-light;
$button-white-font: $color-black;
$button-white-pressed-font: $color-blue;

$body-background: #f6f9fc;
$row-hover: #e2edf4;
$row-even: #f6f9fb;
$row-selected: #fff8cf;

$service-active-table-new: #fff9d9;
$service-active-table-modified: #e2f0fa;
$service-received: $color-white;
$service-received-text: $color-black;
$service-accepted: #ffc7e5;
$service-accepted-text: $color-black;
$service-sent: #c2b961;
$service-sent-text: $color-black;
$service-assigned: #ffa9ad;
$service-assigned-text: $color-black;
$service-located: #ffb942;
$service-located-text: $color-black;
$service-charged: #ffe800;
$service-charged-text: $color-black;
$service-delivered: #61f3fa;
$service-delivered-text: $color-black;
$service-finalized: #acdd75;
$service-finalized-text: $color-black;
$service-delayed: #52dec5;
$service-delayed-text: $color-black;
$service-very_delayed: #a1d0ff;
$service-very_delayed-text: $color-black;
$service-rejected: #d3aafd;
$service-rejected-text: $color-black;

$label-crane-text: $color-black;
$label-crane-free: $color-white;
$label-crane-inactive: $color-white;
$label-crane-assigned: $color-white;
$label-crane-located: $color-white;
$label-crane-charged: $color-white;
$label-crane-delivered: $color-white;

$crane-free: #6dd400;
$crane-inactive: #d8d8d8;
$crane-assigned: $service-assigned;
$crane-located: $service-located;
$crane-charged: $service-charged;
$crane-delivered: $service-delivered;

// Shadows
$shadow-cards: 1px 1px 2px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.06);
$shadow-tooltips: 1px 2px 9px 0 rgba(0, 0, 0, 0.07), 1px 1px 6px 0 rgba(0, 0, 0, 0.12);
$shadow-chat: 4px 2px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 7px 0 rgba(0, 0, 0, 0.11);
$shadow-popups: 0 8px 19px 0 rgba(0, 0, 0, 0.1), 10px 19px 46px 0 rgba(0, 0, 0, 0.07);

:export {
  service-active-table-new: $service-active-table-new;
  service-active-table-modified: $service-active-table-modified;
  service-received: $service-received;
  service-received-text: $service-received-text;
  service-accepted: $service-accepted;
  service-accepted-text: $service-accepted-text;
  service-sent: $service-sent;
  service-sent-text: $service-sent-text;
  service-assigned: $service-assigned;
  service-assigned-text: $service-assigned-text;
  service-located: $service-located;
  service-located-text: $service-located-text;
  service-charged: $service-charged;
  service-charged-text: $service-charged-text;
  service-delivered: $service-delivered;
  service-delivered-text: $service-delivered-text;
  service-finalized: $service-finalized;
  service-finalized-text: $service-finalized-text;
  service-delayed: $service-delayed;
  service-delayed-text: $service-delayed-text;
  service-very_delayed: $service-very_delayed;
  service-very_delayed-text: $service-very_delayed-text;
  service-rejected: $service-rejected;
  service-rejected-text: $service-rejected-text;
  label-crane-text: $label-crane-text;
  label-crane-free: $label-crane-free;
  label-crane-inactive: $label-crane-inactive;
  label-crane-assigned: $label-crane-assigned;
  label-crane-located: $label-crane-located;
  label-crane-charged: $label-crane-charged;
  label-crane-delivered: $label-crane-delivered;
  crane-free: $crane-free;
  crane-inactive: $crane-inactive;
  crane-assigned: $crane-assigned;
  crane-located: $crane-located;
  crane-charged: $crane-charged;
  crane-delivered: $crane-delivered;
}
