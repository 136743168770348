.container {
  position: relative;
  //   border: 1px solid black;
  border-radius: 16px;
  background-color: grey;
}

.qr-link {
  position: absolute;
  color: honeydew;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.title {
  padding-top: 2rem;
  text-align: center;
  color: honeydew;
  font-size: 3rem;
  font-weight: bolder;
}

.values {
  padding-top: 1rem;
  text-align: center;
  color: honeydew;
  font-size: 1.5rem;
  padding-bottom: 2rem;
  span {
    padding-left: 2rem;
  }
}

.modal-container {
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: auto;
  background: white;
  padding: 3rem;
  border-radius: 5px;
}
